<script setup lang="ts">
const baseURL = useRuntimeConfig().app.baseURL;

const route = useRoute();
const hasImage = computed(() => route.path.match(/(login|sign-up)/));
</script>

<template>
  <div class="relative flex items-start h-screen">
    <AuthHeader>
      <div id="header-actions" />
    </AuthHeader>
    <div
      v-if="hasImage"
      class="flex-shrink-0 hidden w-2/5 h-full lg:flex"
    >
      <RImage
        :src="`${baseURL}images/bg_auth_cameras.png`"
        class="object-cover w-full"
      />
    </div>
    <div class="w-full h-full overflow-y-auto bg-white dark:bg-neutral-dark-900 dark:text-neutral-dark-200">
      <NuxtPage />
    </div>
  </div>
</template>
